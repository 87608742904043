@use "../config/" as *;
.waf-statsdetail {
    @extend %p-4;
    .tabs {
        @extend %py-1;
        @extend %px-2;
        @extend %secondary-1000-bg;
    }
    .tab {
        &-container-head {
            @extend %my-6;
            .title {
                @extend %font-16-pb;
                @extend %capitalize;
                @extend %neutral-800;
            }
        }
        &-name {
            height: 3rem;
            @extend %transparent-bg;
            .btn-text {
                @extend %pure-white-900;
                @extend %font-12-pb;
                @extend %uppercase;
            }
            &.active {
                border-radius: var(--half-radius);
                @extend %primary-300-bg;
                .btn-text {
                    @extend %neutral-800;
                }
            }
        }
    }
    .filter-actions,
    .more-filters {
        @extend %d-none;
    }
    .filter {
        &-wrapper {
            @extend %mb-4;
        }
        &-wrap,
        &-tab-wrap {
            @extend %flex;
        }
        &-section,
        &-wrap {
            @extend %w-100;
        }
        &-tab-wrap {
            @extend %p-4;
            @extend %gap-4;
            @extend %flex-wrap;
            @extend %half-radius;
            @extend %pure-white-900-bg;
        }
        &-section {
            position: unset;
        }
        &-text {
            @extend %uppercase;
        }
        &-wrap {
            gap: 0;
            height: 4.6rem;
            border: .1rem solid clr(neutral-50);
            @extend %half-radius;
        }
        &-btn {
            flex: 1;
            border-radius: var(--half-radius);
            @extend %relative;
            &:not(:first-child) {
                &::before {
                    content: "";
                    width: .1rem;
                    @include position-combo("y-center", null, 0);
                    @extend %neutral-1000-bg;
                    @extend %h-50;
                }
            }
            &.active {
                @extend %primary-300-bg;
                .filter-text {
                    font-weight: 700;
                    @extend %neutral-800;
                }
                &::before {
                    content: unset;
                }
                + .filter-btn {
                    &::before {
                        content: unset;
                    }
                }
            }
        }
        &-list {
            list-style: none;
            @extend %pl-0;
            @extend %w-100;
            .filter-btn {
                height: 3.3rem;
                @extend %w-100;
                @extend %text-left;
                @extend %p-1-2;
                &.active {
                    background-color: unset;
                    border-radius: 0;
                    border-bottom: .2rem solid clr(primary-300);
                    .filter-text {
                        font-weight: 500;
                        @extend %secondary-800;
                    }
                }
            }
            .filter-text {
                text-transform: none;
                @extend %font-12-pm;
                @extend %secondary-800;
            }
        }
    }
    .team-ranking {
        .table {
            &-data-matches,
            &-data-points {
                display: flex;
            }
            &-data-players,
            &-data-careerbest {
                display: none;
            }
        }
        .card-thumbnail,
        .player-name {
            @extend %d-none;
        }
        .team-name {
            order: 1;
        }
        .team-name,
        .player-position {
            @extend %font-24;
        }
        .card {
            &-head {
                .team-image {
                    width: 6.5rem;
                }
            }
            &-points {
                width: calc(100% / 3);
                @extend %flex-column-c-c;
                @extend %gap-4;
                .team-image {
                    @extend %d-none;
                }
            }
        }
    }
    //Doubt regarding title --- Need to check with hardik
    .head-wrap {
        @extend %mb-4;
        .title {
            text-transform: none;
            min-height: auto;
            @extend %font-16-pb;
        }
    }
    .player {
        &-image {
            object-fit: cover;
            object-position: top;
            @extend %h-100;
        }
        &-position {
            @extend %font-16-pb;
            @extend %pure-white-900-3;
        }
        &-name {
            @extend %w-100;
            @extend %flex-n-c;
            @extend %flex-wrap;
            @extend %gap-1;
        }
    }
    .name {
        @extend %font-16-pr;
        @extend %pure-white-900;
        @extend %capitalize;
        &:last-child {
            font-weight: 700;
        }
    }
    .card {
        &-item {
            background: linear-gradient(92.5deg, var(--secondary-800) -0.01%, var(--secondary-1000) 133.57%);
            @extend %half-radius;
            @extend %mb-4;
        }
        &-info-wrapper,
        &-head {
            @extend %flex-n-c;
            @extend %gap-4;
        }
        &-info-wrapper,
        &-points,
        &-points-list {
            @extend %flex-wrap;
        }
        &-thumbnail {
            width: 7.9rem;
            height: 7.9rem;
            aspect-ratio: 1;
            border: .2rem solid var(--primary-300);
            flex-shrink: 0;
            @extend %circle-radius;
            @extend %hidden;
            @extend %neutral-300-bg;
        }
        &-head {
            @extend %p-4;
        }
        &-position {
            @extend %font-20-pb;
            @extend %pure-white-900-3;
            @extend %mb-2;
        }
        &-points {
            border-bottom: .1rem solid clr(pure-white-900, 1);
            row-gap: var(--space-4);
            column-gap: var(--space-1);
            @extend %flex-c-c;
            @extend %w-50;
            @extend %py-3;
            @extend %px-1;
            @extend %relative;
            &-list {
                @extend %flex-c-c;
                @extend %pure-white-900-bg-1;
            }
            .text,
            .label {
                line-height: 1;
            }
            .text {
                @extend %font-24-pb;
                @extend %primary-300;
                @extend %uppercase;
            }
            .label {
                @extend %font-14-pr;
                @extend %primary-300;
                @extend %capitalize;
                @extend %text-center;
                @extend %w-100;
            }
            &.careerbest {
                .text {
                    @extend %capitalize;
                    @extend %font-16;
                }
            }
            &:not(:first-child) {
                .team-image {
                    @extend %d-none;
                }
            }
            &:not(:last-child)::after {
                content: '';
                width: .1rem;
                @include position-combo("y-center", 0);
                @extend %neutral-50-bg-2;
                @extend %h-50;
            }
        }
    }
    .team {
        &-image {
            width: 2.5rem;
            height: auto;
            border: .04rem solid clr(pure-white-900, 3);
            @extend %half-radius;
        }
        &-name {
            @extend %font-16-pb;
            @extend %pure-white-900;
            &-link {
                color: inherit;
                font: inherit;
                width: max-content;
                max-height: 100%;
            }
        }
    }
    .table {
        overflow-x: auto;
        border: .1rem solid clr(neutral-100, 2);
        .text {
            @extend %neutral-800;
            @extend %font-10-pb;
        }
        &-responsive {
            .btn-more {
                padding-inline: var(--space-4);
                @include btn-animation();
                height: 3.4rem;
                @extend %mx-auto;
                @extend %my-4;
                &::before {
                    @extend %d-none;
                }
                &::after {
                    font-weight: 400;
                }
                .btn-text {
                    @extend %font-12-pb;
                    @extend %uppercase;
                }
            }
        }
        &-head {
            @extend %neutral-1000-bg;
            .text {
                @extend %font-10-pb;
                @extend %uppercase;
            }
            .table-data {
                --_table-head-data-height: 3.3rem;
                @extend %neutral-1000-bg;
            }
        }
        &-row {
            border-bottom: 0;
        }
        &-body {
            .table-row {
                &:not(:last-child) {
                    .table-data {
                        border-bottom: .1rem solid clr(neutral-100, 2);
                    }
                }
            }
            .table-data {
                --_table-data-height: 5.8rem;
                @extend %pure-white-900-bg;
                &.highlight {
                    @extend %primary-50-bg;
                    .text {
                        @extend %font-10-pb;
                    }
                }
            }
            .table-data-players {
                @extend %text-left;
                .text {
                    @extend %capitalize;
                }
            }
            .table-data-team {
                @extend %relative;
                &-link {
                    @extend %flex-n-c;
                    @extend %gap-1;
                }
                &::before {
                    content: '';
                    width: .1rem;
                    height: 30%;
                    left: 0;
                    @extend %neutral-100-bg;
                    @extend %pos-y-center;
                }
            }
            .range {
                @extend %flex-n-c;
                @extend %font-10-pb;
                @extend %gap-2;
            }
            .neutral {
                height: .5rem;
                aspect-ratio: 1;
                @extend %font-0;
                @extend %neutral-100-bg;
                @extend %circle-radius;
            }
            .up {
                @extend %success-800;
                &:before {
                    @include icon(p-arrow-up, 7);
                }
            }
            .down {
                @extend %error-800;
                &:before {
                    @include icon(p-arrow-down, 7);
                }
            }
            .points {
                @extend %ml-auto;
                .text {
                    @extend %ml-2;
                }
            }
        }
        &-left {
            width: unset;
            max-width: 40%;
            flex: 1;
        }
        &-right {
            max-width: 60%;
            flex: 1;
            width: unset;
            overflow: unset;
        }
        &-data {
            width: 7.4rem;
            flex: 1;
            @extend %flex-fs-c;
            @extend %px-2;
        }
        &-data-rating,
        &-data-careerbest,
        &-data-matches {
            justify-content: center;
        }
        &-data-position {
            max-width: 5.5rem;
            @extend %gap-2;
        }
        &-data-team {
            @extend %gap-2;
            .full-name {
                @extend %d-none;
            }
        }
        &-data-careerbest,
        &-data-matches,
        &-data-points {
            display: none;
        }
        .no-data-wrap {
            border: .1rem solid var(--secondary-900);
            @extend %p-3;
            @extend %text-center;
            @extend %uppercase;
            @extend %full-radius;
            @extend %secondary-900;
            @extend %font-16-pb;
        }
    }
    .stats-wrapper {
        .title {
            @extend %font-16-pb;
            @extend %mb-4;
            @extend %d-none;
        }
        &:not(.team-ranking) {
            .card-head {
                .team-name,
                .team-image-link {
                    @extend %d-none;
                }
            }
            .card-info-wrapper {
                row-gap: var(--space-2);
            }
        }
    }
}
@include mq(col-md) {
    .waf-statsdetail {
        padding-inline: 0;
    }
}
@include mq(col-lg) {
    .waf-statsdetail {
        .waf-body {
            display: flex;
            gap: var(--space-4);
        }
        .filter-wrapper {
            width: 30rem;
        }
        .head-wrap .title {
            display: none;
        }
        .stats-wrapper {
            width: calc(100% - 30rem);
            background-color: var(--pure-white-900);
            padding: var(--space-4);
            border-radius: var(--half-radius);
            .title {
                display: block;
            }
            &:not(.team-ranking) {
                .card-head {
                    .team-name,
                    .team-image-link {
                        display: inline-block;
                    }
                }
                .team-name {
                    width: calc(100% - 8.8rem);
                }
                .player-name {
                    width: auto;
                }
            }
        }
        .team-image {
            width: 3.6rem;
        }
        .table {
            &-responsive {
                .btn-more::before {
                    display: block;
                }
            }
            &-left {
                max-width: 30%;
            }
            &-right {
                max-width: 70%;
            }
            &-body {
                .table-data {
                    --_table-data-height: 6.4rem;
                    font-size: 1.2rem;
                }
            }
            &-data-careerbest {
                display: flex;
            }
            &-data-team {
                .full-name {
                    display: flex;
                }
                .short-name {
                    display: none;
                }
            }
        }
        .player-position,
        .name {
            font-size: 2.4rem;
        }
        .card {
            &-item {
                @include flex-config(flex, row, space-between, center);
            }
            &-head {
                width: 50%;
                .team-image {
                    width: 4.9rem;
                }
            }
            &-body {
                padding: var(--space-4);
            }
            &-points {
                .team-image {
                    display: none;
                }
                &-list {
                    border-radius: var(--half-radius);
                    flex-wrap: nowrap;
                }
            }
        }
        .team-ranking .card-points,
        .card-points {
            border-bottom: none;
            width: auto;
            padding-inline: var(--space-4);
        }
        .team-ranking .card-head .team-image {
            width: 8.8rem;
        }
    }
}